<template>
  <div class="login-container">
    <el-form :model="ruleForm2" :rules="rules2" status-icon ref="form" label-position="left" label-width="0px" class="login-page">
      <h3 class="title">系统登录</h3>
      <el-form-item prop="username">
        <el-input type="text" v-model.trim="ruleForm2.username" auto-complete="off" @keyup.enter.native="handleSubmit" placeholder="请输入用户名">
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input type="password" v-model.trim="ruleForm2.password" auto-complete="off" @keyup.enter.native="handleSubmit" placeholder="请输入密码">
        </el-input>
      </el-form-item>
      <el-form-item style="width:100%;" prop="errorMessage">
        <el-button type="primary" style="width:100%;" @click="handleSubmit" :loading="logining">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import md5 from 'js-md5'
import CryptoJS from 'crypto-js'
export default {
  props: {
  },
  data() {
    let errShow = (rule, value, callback) => {
      if (!this.Bus.isEmpty(value)) {
        callback(new Error(value))
      }else{
        callback()
      }
    }
    return {
      logining: false,
      data: {
      },
      errInfo: {
        show: false,
        msg: ''
      },
      ruleForm2: {
        username: '',
        password: '',
        login_type: 'normal',
        errorMessage: ''
      },
      rules2: {
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
        errorMessage: [
          { validator: errShow, trigger: 'change' }
        ]
      },
      checked: false
    }
  },
  methods: {
    handleSubmit(event) {
      let me = this
      let { username, password } = me.ruleForm2
      if (username == '' || password == '') {
        return
      }
      //清空之前跳转过来的错误码
      this.ruleForm2.errorMessage=''
      this.$refs.form.validate(valid => {
        if (valid) {
          me.logining = true
          //生成一个随机字符串
          let nonce = me.randomString()
          console.log(`md5:${password}`)
          //密码混淆
          let nowpwd = md5(`${username}|${md5(password)}|${nonce}`).toLowerCase()
          console.log(`nowpwd:${nowpwd}`)
          //生成签名
          let sign = CryptoJS.HmacSHA256(username, nowpwd).toString()
          console.log(`sign:${sign}`)
          me.Bus.ActionFunc('S-Login', {
            username: username,
            nonce: nonce,
            pwd: sign
          },
            data => {
              me.logining = false
              if (data) {
                this.$store.commit('UpdateLoginState', data.login_code)
                if (this.$route.query.redirect) {
                  let url = decodeURIComponent(this.$route.query.redirect)
                  this.$router.push({ path: url })
                } else {
                  this.$router.push({ path: '/' })
                }
              }
            },
            () => (me.logining = false),
            () => (me.logining = false)
          )
        } else {
          me.$message('请输入账号密码!')
          return false
        }
      })
    },
    randomString() {
      let len = 32;
      var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
      var maxPos = $chars.length;
      var pwd = '';
      for (let i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    }
  },
  mounted() {
    let msg = this.$route.params.errorMessage
    if (!this.Bus.isEmpty(msg)) {
      // this.$set(this, 'errInfo', {
      //   show: true,
      //   msg: msg
      // })
      //this.$set(this.ruleForm2,'errorMessage',msg)
      this.ruleForm2.errorMessage=msg
      this.$refs.form.validateField('errorMessage');
    }
  }
}
</script>

<style scoped>
.login-container {
  width: 100%;
  height: 100%;
}
.login-page {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin: 180px auto;
  width: 350px;
  padding: 35px 35px 15px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}
label.el-checkbox.rememberme {
  margin: 0px 0px 15px;
  text-align: left;
}
</style>
